import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { openMenu, closeMenu } from "../../data/actions";
import styles from "./burger.module.scss";

const Burger = () => {
	const menu = useSelector((store) => store.menu);
	const dispatch = useDispatch();

	const handleSetOpenMenu = () => {
		dispatch(openMenu());
	};
	const handleSetCloseMenu = () => {
		dispatch(closeMenu());
	};

	const burgerViev = !menu ? (
		<div onClick={handleSetOpenMenu}>
			<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
				<path d="M0 0h24v24H0z" fill="none" />
				<path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
			</svg>
		</div>
	) : (
		<div onClick={handleSetCloseMenu}>
			<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
				<path d="M0 0h24v24H0z" fill="none" />
				<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
			</svg>
		</div>
	);

	return <div className={styles.wrapper}>{burgerViev}</div>;
};

export default React.memo(Burger);
